require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/Chat/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var command, nickName;

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

/**
 * this function generates a unique ID that identifies this
 * current user. The ID is then used to decide if the name
 * of the current user is displayed for their own messages
 */
async function generate_My_ID() {
  ___arguments.context.pageData['myID'] = (generateUUID().long());
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function getChannelInstance(channelName) { const channelsMap = getGlobalEntitiesMap('channels');if(!channelsMap[channelName] || !channelsMap[channelName].connection) { channelsMap[channelName] = Backendless.Messaging.subscribe(channelName); }return channelsMap[channelName];}

/**
 * This is the main "engine" for receiving chat messages and adding
 * them to the repeater that renders them. Any important thing
 * that happens here is "linking" the new incoming message to the
 * previous one in the list. This is needed to determine (later)
 * if the name of the sender should be displayed for this message
 */
async function subscribe_to_receive_chat_messages() {

  ;(function() {
    const callback = async command => {
        console.log(command);
    (getObjectProperty(command, 'data'))['prevMessage'] = (!((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('fbaf199bba5204a92d367d9f7c6d6511')).length ? null : ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('fbaf199bba5204a92d367d9f7c6d6511'))[0]);
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('fbaf199bba5204a92d367d9f7c6d6511')).splice(0, 0, (getObjectProperty(command, 'data')));

    };

    const channel = getChannelInstance('chat');

    channel.addCommandListener(callback);


  })();
}

/**
 * Set the chat list to an empty list so the chat
 * starts with an empty collection. New incoming
 * messages are added to the collection in the repeater
 */
async function initialize_chat_list() {
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fbaf199bba5204a92d367d9f7c6d6511', []);
}

/**
 * Check if the nickname already exists in local storage. If it is there,
 * retrieve and use it. Otherwise, prompt the user to enter their nickname
 */
async function get_nickname_or_ask_for_one() {
  nickName = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('nickName'));
  if (nickName) {
    ___arguments.context.pageData['nickName'] = nickName;
    ___arguments.context.pageData['showNicknameModal'] = false;
  } else {
    ___arguments.context.pageData['showNicknameModal'] = true;
  }
}


  await generate_My_ID();
  await get_nickname_or_ask_for_one();
  await initialize_chat_list();
  await subscribe_to_receive_chat_messages();

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['showNicknameModal'] = false;

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Chat/components/3b73583da5ea180dcdfe60a88248aa47/bundle.js', [], () => ({
  /* content */
  /* handler:onKeyDown */
  async ['onKeyDown'](___arguments) {
    var unsafe, x;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function getChannelInstance(channelName) { const channelsMap = getGlobalEntitiesMap('channels');if(!channelsMap[channelName] || !channelsMap[channelName].connection) { channelsMap[channelName] = Backendless.Messaging.subscribe(channelName); }return channelsMap[channelName];}

/**
 * Describe this function...
 */
async function escape_special_chars(unsafe) {
  return ((((unsafe.replace((new RegExp('&', 'g')), '&amp;')).replace((new RegExp('<', 'g')), '&lt;')).replace((new RegExp('>', 'g')), '&gt;')).replace((new RegExp('"', 'g')), '&quot;')).replace((new RegExp('\'', 'g')), '&#039;')
}


  if (___arguments.keyCode == 13) {
    await (getChannelInstance('chat')).send('MSG', ({ 'message': await escape_special_chars((getObjectProperty(___arguments.context.pageData, 'message'))),'sender': await escape_special_chars((getObjectProperty(___arguments.context.pageData, 'nickName'))),'myID': (getObjectProperty(___arguments.context.pageData, 'myID')),'time': ((new Date()).toLocaleTimeString()) }));
    ___arguments.context.pageData['message'] = '';
  }

  },
  /* handler:onKeyDown */
  /* content */
}))

define('./pages/Chat/components/4e8d9a489e92276172d99bbc708126b3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['showNicknameModal'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Chat/components/fc9fbc9844bfff5b40cef5f613772e41/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!((getObjectProperty(___arguments.context.getComponentDataStoreByUid('73d2bf0c84ed77635eba7b37c80d331b'), 'myID')) == (getObjectProperty(___arguments.context.pageData, 'myID')) || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('73d2bf0c84ed77635eba7b37c80d331b'), 'myID')) == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('73d2bf0c84ed77635eba7b37c80d331b'), 'prevMessage.myID'))))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Chat/components/02d1bf9de425f85ef06f95c47c492311/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('73d2bf0c84ed77635eba7b37c80d331b'), 'myID')) == (getObjectProperty(___arguments.context.pageData, 'myID')) ? ['msg', 'sent'] : ['msg', 'rcvd'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Chat/components/602e198ee1a6009fe075b62fb984c545/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  localStorage.setItem('nickName', JSON.stringify((getObjectProperty(___arguments.context.pageData, 'nickName'))));
  ___arguments.context.pageData['showNicknameModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Chat/components/a0db6aaf2f5e165bc374a45a7e49217e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'showNicknameModal')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Chat/components/ca16acf242a2ca57b75b1246ba450094/bundle.js', [], () => ({
  /* content */
  /* handler:onKeyDown */
  ['onKeyDown'](___arguments) {
      if (___arguments.keyCode == 13) {
    ___arguments.context.pageData['showNicknameModal'] = false;
  }

  },
  /* handler:onKeyDown */
  /* content */
}))

define('./pages/Login/components/dad1974295affb682632e651c284b0b5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    await Backendless.UserService.login( (getObjectProperty(___arguments.context.pageData, 'email')), (getObjectProperty(___arguments.context.pageData, 'password')), true  );
    ___arguments.context.pageData['statusMessage'] = (String('User logged in. Hello!') + String(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'Name')));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4919cc93e8b64d279c78c44e46ef8877', true);
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Search', ___arguments.context.appData);

  } catch (error) {
    ___arguments.context.pageData['statusMessage'] = (String('Login failed. Server returned an error.') + String(getObjectProperty(error, 'message')));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4919cc93e8b64d279c78c44e46ef8877', true);
    console.log(error);

  } finally {
    console.log(await Backendless.UserService.getCurrentUser(false));
    ___arguments.context.appData['Name'] = (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'Name'));

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Login/components/4653592473f7a412c17ac29add92a273/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Welcome', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Login2/components/85dae34486144e2efa2d934bfdddb172/bundle.js', [], () => ({
  /* content */

  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var user, error, password, email, stayLoggedIn, logging, buttonLogin;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function login() {
  try {
    user = (await Backendless.UserService.login( email, password, stayLoggedIn  ));

  } catch (error) {
    await setError(error);

  }
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}

/**
 * Describe this function...
 */
async function updateButtonLogin(logging) {
  buttonLogin = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  buttonLogin['label'] = (logging ? 'Please wait...' : 'Login');
  buttonLogin['disabled'] = logging;
}


  await setError(null);
  email = (getObjectProperty(___arguments.context.dataModel, 'email'));
  password = (getObjectProperty(___arguments.context.dataModel, 'password'));
  stayLoggedIn = (getObjectProperty(___arguments.context.dataModel, 'stayLoggedIn'));
  if (email && password) {
    await updateButtonLogin(true);
    try {
      await login();

    } catch (error) {
      if ((getObjectProperty(error, 'code')) == 3033) {
        await login();
      } else {
        await setError(error);
      }

    } finally {
      await updateButtonLogin(false);

    }
  } else {
    await setError((new Error('Email and Password must be filled')));
  }
  if (user) {
    ___arguments.context.appData['currentUser'] = user;
    ___arguments.context.appData['userName'] = (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'Name'));
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Search', ___arguments.context.appData);
  }

  },
  /* handler:onSubmit *//* content */
}));

define('./pages/OrgSignUp/components/c04e80b166932fc28bcdac7941311797/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['venue'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/OrgSignUp/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('c04e80b166932fc28bcdac7941311797', (await Promise.all((await Backendless.Data.of('venue').find(Backendless.DataQueryBuilder.create().setPageSize(30))).map(async item => {; return ({ [`label`]: (getObjectProperty(item, 'Name')),[`value`]: (getObjectProperty(item, 'Name')) });}))));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/OrgSignUp/components/4f54203cb63f9002fad1a0216d7bd743/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a5c4d57ada8fe42217433b2501edd474'), 'password')) != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a5c4d57ada8fe42217433b2501edd474'), 'password2'))) {
    ___arguments.context.getComponentDataStoreByUid('a5c4d57ada8fe42217433b2501edd474')['statusMessage2'] = 'Passwords do not match!';
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e8867d227146ce63ea38bdc75c6877fb', true);
  } else {
    await Backendless.UserService.register( new Backendless.User({ 'Name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a5c4d57ada8fe42217433b2501edd474'), 'Name')),'email': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a5c4d57ada8fe42217433b2501edd474'), 'email')),'password': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a5c4d57ada8fe42217433b2501edd474'), 'password')),'Organization': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a5c4d57ada8fe42217433b2501edd474'), 'organization')),'instrument': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a5c4d57ada8fe42217433b2501edd474'), 'instrument')),'phone': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a5c4d57ada8fe42217433b2501edd474'), 'phone')) }) );
    ___arguments.context.getComponentDataStoreByUid('a5c4d57ada8fe42217433b2501edd474')['statusMessage2'] = 'Success! Check your email for a confirmation link!';
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e8867d227146ce63ea38bdc75c6877fb', true);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrgSignUp/components/a786a00cbc89245583e231ca72fddcbe/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login2', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/OrgSignUp/components/90772c1b9e221dd4d9e1254ca9d809df/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Welcome', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Search/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('5436c3ecdbca2bc8207da6cada8900a6', (await Promise.all((await Backendless.Data.of('instrument').find(Backendless.DataQueryBuilder.create().setSortBy('Name').setPageSize(100))).map(async item => {; return ({ [`label`]: (getObjectProperty(item, 'Name')),[`value`]: (getObjectProperty(item, 'Name')) });}))));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/Search/components/02c96958c8a51686aecd0c648298a4b8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('db5678e4efdb208999c4f29e9f2a2567', (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['instrument LIKE \'%',(getObjectProperty(___arguments.context.pageData, 'select')),'%\''].join(''))).setPageSize(50))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Search/components/5436c3ecdbca2bc8207da6cada8900a6/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['artists'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Search/components/a91669c49b8458db42614fdc01a1526f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Welcome', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Search/components/e048fd1baab81769dc15b77df4cfcee2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Profile', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/SignUp/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await ( async function (componentUid, options) {  ___arguments.context.getComponentByUid(componentUid).setOptions(options) })('0d3d2e09b89478192e221eb9c68ddb95', (await Promise.all((await Backendless.Data.of('instrument').find(Backendless.DataQueryBuilder.create().setPageSize(30))).map(async item => {; return ({ [`label`]: (getObjectProperty(item, 'Name')),[`value`]: (getObjectProperty(item, 'Name')) });}))));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/SignUp/components/4f54203cb63f9002fad1a0216d7bd743/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a5c4d57ada8fe42217433b2501edd474'), 'password')) != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a5c4d57ada8fe42217433b2501edd474'), 'password2'))) {
    ___arguments.context.getComponentDataStoreByUid('a5c4d57ada8fe42217433b2501edd474')['statusMessage'] = 'Passwords do not match!';
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e8867d227146ce63ea38bdc75c6877fb', true);
  } else {
    await Backendless.UserService.register( new Backendless.User({ 'Name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a5c4d57ada8fe42217433b2501edd474'), 'Name')),'email': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a5c4d57ada8fe42217433b2501edd474'), 'email')),'password': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a5c4d57ada8fe42217433b2501edd474'), 'password')),'Organization': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a5c4d57ada8fe42217433b2501edd474'), 'band')),'instrument': ((await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getValue() })('0d3d2e09b89478192e221eb9c68ddb95')).map(item => item['value'])).join(','),'phone': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a5c4d57ada8fe42217433b2501edd474'), 'phone')) }) );
    ___arguments.context.getComponentDataStoreByUid('a5c4d57ada8fe42217433b2501edd474')['statusMessage'] = 'Success! Check your email for a confirmation link!';
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e8867d227146ce63ea38bdc75c6877fb', true);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/SignUp/components/a786a00cbc89245583e231ca72fddcbe/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login2', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/SignUp/components/90772c1b9e221dd4d9e1254ca9d809df/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Welcome', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/SignUp/components/aab6093edd6a2c9185879237ce0ca894/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('OrgSignUp', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/SignUp/components/0d3d2e09b89478192e221eb9c68ddb95/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(((await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getValue() })('0d3d2e09b89478192e221eb9c68ddb95')).map(item => item['instrument'])).join(','));
  ___arguments.context.getComponentDataStoreByUid('a5c4d57ada8fe42217433b2501edd474')[(getObjectProperty(___arguments.context.pageData, 'instrument'))] = ___arguments['selectValue'];

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Welcome/components/c0aac99608223a4d2c840cfa205624fe/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('SignUp', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Welcome/components/a5370732637acecdf7c29bbfc339f54a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login2', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Welcome/components/bc0c4b5cb86bd3f8ae01dadf0f43f03b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Search', ___arguments.context.appData);
  if ((await Backendless.UserService.getCurrentUser(false)) == null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login2', ___arguments.context.appData);
  }

  },
  /* handler:onClick */
  /* content */
}))

